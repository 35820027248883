@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;


/*===============================================================
# Common
===============================================================*/
a {
    color: inherit;
    text-decoration: none;
}

li {
    list-style-type: none;
}



.heading {
    margin-bottom: 5%;

    h3 {
        text-align: center;
        text-transform: capitalize;
        font-size: 3vw;
        margin: 0;
        font-family: $title-family;
        font-weight: bold;
        position: relative;

        &:before {
            content: '';
            height: 2px;
            width: 10%;
            background: #000;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:after {
            content: '';
            height: 2px;
            width: 8%;
            background: #000;
            position: absolute;
            top: 110%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

/*===============================================================
# Whatsapp
===============================================================*/
.whatsapp {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 70000;
    background-color: #fff;
    border-radius: 50%;
    filter: drop-shadow(2px 2px 5px $light-gray);
    display: none;

    img {
        width: 50px;
        height: 50px;
    }
}

/*===============================================================
# Navbar
===============================================================*/
.header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 2px 2px 10px rgba($color: $color-1, $alpha: 0.2);
    z-index: 2000;
    background: #fff;
    position: relative;
    // padding: 2% 0;
    @include flex;
    align-items: center;
    justify-content: space-between;

    .header-logo {

        width: 20%;

        img {
            width: 110px;
            display: block;
            margin: auto;
        }

        h1 {
            font-size: 2.8vw;
            letter-spacing: 1px;
            font-family: $header-family;
            margin: 2%;
        }
    }

    .header-links {
        width: 80%;
        text-transform: uppercase;
        @include flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5%;

        a {
            position: relative;
            z-index: 2;
            margin: 0 1%;
            font-size: 1.1vw;
            padding: .5% 1%;
            font-weight: 500;

            &:hover {
                color: $color_2;
                transform: scale(1.1);
                font-weight: bold;
                transition: .5s all ease;
                border-top: 1px solid $color-1;
                border-bottom: 1px solid $color-1;

                &:last-child {
                    border: none;
                }
            }

            img {
                width: 35px;
                height: 35px;
            }
        }
    }

    .header-menu {
        margin-right: 5%;
        border-radius: 5px;
        display: none;

        svg {
            width: 50px;
            height: 50px;

            path {
                fill: $color-1;
            }
        }
    }
}

.header-mobile {
    position: fixed;
    top:0;
    right: 0;
    height: 100%;
    z-index: 5000;
    width: 50%;
    background: #fff;
    padding: 5%;
    display: none;
    animation: slide-in-right .5s forwards;
    transition: 1s all ease;

    svg {
        fill: $color-1;
        width: 30px;
        height: 30px;
        float: right;
        overflow: hidden;
    }

    @keyframes slide {
        100% {
            right: 0;
        }
    }

    .header-mobile-links {
        @include flex;
        flex-direction: column;
        margin-top: 30%;

        a {
            font-size: 3.5vw;
            margin: 3% 0;
            font-weight: 500;

            &:hover {
                color: $color_2;
                transform: scale(1.1);
                font-weight: bold;
                transition: .5s all ease;
            }


        }
    }
}

/*===============================================================
# banner
===============================================================*/
.banner {
    position: relative;
    margin-top: 5%;

    img {
        width: 100%;
        // filter: brightness(80%);
        height: 250px;
        object-fit: cover;
    }

    .banner-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        text-shadow: 2px 2px 10px #000;
        color: #fff;
        background: rgba($color: #000000, $alpha: 0.5);
        padding: 5%;

        h3 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 3vw;
            text-transform: capitalize;
            text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.5);
            margin: 0;
            font-family: 'Charm', cursive;

            // letter-spacing: 1px;
            a {}
        }


    }
}


/*===============================================================
# carousel
===============================================================*/
.carousel {
    position: relative;
    margin-top: 7%;

    .carousel-wrapper {
        width: 100%;

        .carousel-body {
            img {
                filter: brightness(50%);
            }

            .carousel-caption {
                position: absolute;
                top: 0;
                left: 5%;
                // transform: translate(-50%, -50%);
                color: #fff;
                width: 50%;
                height: 100%;
                padding-top: 10%;

                h5 {
                    font-size: 3.2vw;
                    text-transform: uppercase;
                    text-shadow: 2px 2px 2px #000;
                    margin-bottom: 5%;
                    font-family: $title-family;
                    font-weight: bolder;
                    line-height: 150%;
                }

                a {
                    background: $gradient-1;
                    text-transform: capitalize;
                    padding: 2% 7%;
                    border-radius: 10px;
                    font-size: 1.7vw;
                    text-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.2);
                    border: 1px solid #fff;

                    &:hover {
                        transition: 1s all ease;
                        background: transparent;
                    }
                }
            }
        }


    }

    .carousel-form {
        position: absolute;
        right: 3%;
        top: 10%;
        padding: 2% 3%;
        width: 40%;

        h4 {
            color: #fff;
            text-align: center;
            margin-bottom: 2%;
            font-family: $title-family;
            font-size: 1.5vw;
            font-weight: bold;
            letter-spacing: 1px;
            background: $gradient-1;
            padding: 3% 2%;
        }

        .carousel-form-body {
            @include flex;
            justify-content: space-between;

            input,
            textarea,
            select {
                background: rgba($color: #fff, $alpha: 0.7);
                width: 50%;
                border: none;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
                margin: 1%;
                padding: 2% 5%;
                outline: none;
                font-family: $text-family;
                font-size: 1.2vw;

                &::placeholder {
                    color: #000;
                }
            }

            select {
                background: rgba(255, 255, 255, .7) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e") no-repeat right .5em center/15px 15px !important;
            }

            textarea {
                width: 100% !important;
            }


        }

        button {
            margin-top: 2%;
            width: 100%;
            background: $gradient-1;
            padding: 2% 0;
            border: none;
            color: #fff;
            border-radius: none !important;
        }

    }


}


/*===============================================================
# about
===============================================================*/
.about {
    padding: 5%;
    @include flex;
    background: rgba($color: $color-1, $alpha: 0.3);

    img {
        width: 40%;
        object-fit: cover;
        border: 5px solid $light-gray;
    }


    .about-text {
        width: 60%;
        padding: 0 5%;

        h3 {
            margin-bottom: 4%;
            font-family: $title-family;
            font-weight: bold;
        }

        p {
            font-size: 1.1vw;
            font-family: $text-family;
        }
    }
}

.about-page {
    .about-body {
        padding: 8% 5% 5% 5%;

        .founder-message {
            @include flex;
            justify-content: space-between;

            .img {
                width: 50%;
                position: relative;

                img {
                    width: 100%;
                    padding: 0 5% 5% 5%;
                    position: relative;
                    z-index: 3;
                }

                .img-before {
                    position: absolute;
                    top: -8%;
                    left: -2%;
                    background: rgba($color: $dark-gray, $alpha: 0.2);
                    content: '';
                    height: 90%;
                    width: 90%;
                    z-index: 2;
                }
            }



            .founder-message-caption {
                width: 50%;

                h3 {
                    font-family: $title-family;
                    font-weight: bold;
                    position: relative;
                    margin-bottom: 3%;
                    margin-top: -4%;

                    &:after {
                        content: '';
                        width: 55%;
                        height: 2px;
                        position: absolute;
                        background: #000;
                        top: 50%;
                        right: 0;
                    }
                }

                p {
                    font-size: 14px;
                    font-family: $text-family;
                    margin-bottom: 1%;
                }
            }
        }

        .why-choose-us {
            margin-top: 5%;

            h3 {
                margin-bottom: 3%;
                font-family: $title-family;
                font-weight: bold;
                text-transform: capitalize;
                text-align: center;
            }

            p {
                font-size: 16px;
                font-family: $text-family;
            }

        }
    }
}

/*===============================================================
# Courses
===============================================================*/
.courses {
    padding: $padding;

    .courses-body {
        @include flex;
        flex-wrap: wrap;

        .courses-card {
            width: calc(100% / 3.2);
            margin: 1%;
            background: rgba($color: $color-1, $alpha: 0.4);
            border-radius: 5px;


            img {
                width: 80%;
                margin: auto;
                display: block;
                margin-top: 4%;
                // border: 1px solid $brown;
            }

            h4 {
                text-transform: capitalize;
                font-size: 2vw;
                text-align: center;
                margin: 3% 0 5% 0;
                font-family: $text-family;
                // color: #fff;
            }
        }
    }
}

.courses-page {
    .heading {
        margin: 5% 0;
    }

    .courses-body {
        padding: 0 5%;

        .courses-card {
            @include flex;
            margin: 5% 0;

            .courses-card-caption {
                width: 60%;

                h4 {
                    font-family: $header-family;
                    font-weight: bold;
                    font-size: 4vw;
                    letter-spacing: 2px;
                    margin: 0;
                    text-transform: capitalize;
                }

                span {
                    font-family: $title-family;
                    font-style: italic;
                    font-size: 1.5vw;
                    text-transform: capitalize;
                }

                p {
                    font-family: $text-family;
                    font-size: 1.5vw;
                    margin: 5% 0;
                }
            }

            .courses-img {
                width: 40%;
                margin-right: 5%;

                img {
                    width: 100%;
                    display: block;
                    margin: auto;
                    border: 5px solid $dark-gray;
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .courses-img {
                    margin-right: 0;
                    margin-left: 5%;
                }
            }

        }


    }
}

/*===============================================================
# affiliations
===============================================================*/
.affiliations {
    margin-top: 5%;
    padding: 5%;
    background: rgba($color: $color-1, $alpha: 0.3);
    ;
    animation: 1s ease-out 0s 1 slideInLeft;

    .cer-body {
        .cer-body-img {
            display: flex;
            justify-content: space-around;
            align-items: center;
            max-width: 100%;
            margin: auto .5em;
        }
    }
}

#Frames {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    text-align: center;
    margin: 1%;
    width: calc(100%/3);
}

.Frame {
    list-style: none;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    text-align: center;
    display: inline-block;
    padding: 40px;
    border-width: 20px;
    border-style: solid;
    border-color: $black;
    background: #f9f9f9;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, .6), 0 5px 2px rgba(0, 0, 0, .1), 0 10px 20px rgba(0, 0, 0, .8);
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: -175px;
        right: -20%;
        width: 400px;
        height: 400px;
        transform: rotateZ(-40deg);
        -webkit-transform: rotateZ(-40deg);
        -moz-transform: rotateZ(-40deg);
        -o-transform: rotateZ(-40deg);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(255, 255, 255, .4)), to(rgba(255, 255, 255, 0)));
        background-image: -webkit-linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
        background-image: -moz-linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
        background-image: -o-linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
        background-image: linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, 0));
    }

    img {
        border-width: 2px;
        border-style: solid;
        border-color: $color-1;
        box-shadow: 0 -1px 1px rgba(0, 0, 0, .1), 0 1px 1px 1px rgba(255, 255, 255, .7);
    }
}

/*===============================================================
# Team
===============================================================*/

.team {
    margin: 5% 0;

    .gray {
        color: $color_1;
    }

    .team-member {
        margin: 1%;
        padding: 2% 0;
        box-shadow: 2px 2px 10px rgba($color: #000, $alpha: 0.2);
        border: 2px solid $light-gray;

        figure {
            position: relative;
            overflow: hidden;
            padding: 0;
            margin: 0;

            img {
                width: 100%;
                transform: scale(1) rotate(0) translateY(0);
                transition: all 0.4s ease-in-out;
            }

            &:hover {
                figcaption {
                    background: $color-1;

                    ul {
                        visibility: visible;
                    }

                    color: $color_3;
                    height: 100%;
                    transition: all 0.3s ease-in-out;
                    visibility: visible;

                }

                img {
                    transform: scale(1.1) rotate(1deg) translateY(12px);
                    transition: all 0.4s ease-in-out;
                }
            }
        }

     

        h4 {
            margin: 10px 0 0;
            padding: 0;
            font-size: 1.3vw;
            font-family: $text-family;
            font-weight: bold;
            text-align: center;
        }

        p {
            font-size: .8vw;
            font-family: $text-family;
            text-align: center;
            text-transform: capitalize;
        }
    }

}

.team-page {
    margin-bottom: 5%;

    .team-body {
        padding: $padding;
        @include flex;
        flex-wrap: wrap;

        .team-card {
            @include flex;
            width: 100%;
            box-shadow: 2px 2px 5px rgba($color: $dark-gray, $alpha: 0.3);
            margin: 3% 1%;
            padding: 2%;

            &:nth-child(even) {

                flex-direction: row-reverse;

                img {
                    margin-left: 5%;
                    margin-right: 7%;
                }

                .team-text {
                    margin-left: 0;
                }
            }

            .team-img {
                width: 26%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-right: 5%;
                    margin-bottom: 3%;
                }
            }

            .team-text {
                width: 70%;
                padding: 3% 0;
                margin-left: 5%;

                .team-title {
                    margin-bottom: 2%;
                    text-transform: capitalize;

                    h3 {
                        font-family: $title-family;
                        font-size: 1.5vw;
                        margin-bottom: 0;
                        font-weight: bolder;
                    }

                    span {
                        font-size: 1.2vw;
                        font-family: $text-family;
                        color: $gray;
                        margin: 0 0 1% 0;
                        font-style: italic;
                        color: $color-1;
                    }
                }


                .team-caption {
                    p {
                        font-size: 1vw;
                        font-family: $text-family;
                        color: $dark-gray;
                        margin: 0 0 1% 0;
                    }
                }


            }
        }
    }
}

/*===============================================================
# achievements
===============================================================*/
#achievements {
    padding: 5%;
    background: rgba($color: $color-1, $alpha: 0.3);
}

.achievements-content-img {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    img {
        border: 4px solid var(--brown);
        object-fit: cover;
        width: calc(100% /4.4);
        margin: 1%;
    }
}

.achievements-content-text {
    color: $color_1;
    margin-top: 5%;

    h2 {
        color: $color_1;
        text-shadow: 2px 2px 1px var(--golden);
    }
}

/*===============================================================
# Gallery
===============================================================*/
#gallery {
    padding: 5%;
    background: rgba($color: $color-1, $alpha: 0.3);

    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
            width: 25%;
            transition: transform .2s;

            &:hover {
                transform: scale(1.4);
            }
        }
    }
}

.pages-gallery {
    padding: $padding;
    color: $color_1;
    width: 100%;
    margin-bottom: 3%;

    #achievements {
        padding: 1% 4%;
    }

    img {
        width: calc(100% / 3);
        margin: 1%;
        border: 1px solid $color-1;

        &:hover {
            transform: scale(1.1);
            transition: 1s all ease;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 4px;
    }

    .column {
        flex: 35%;
        max-width: calc(100% / 3);
        padding: 0 4px;

        img {
            margin-top: 8px;
            vertical-align: middle;
            width: 100%;
        }
    }
}

.gallery-filter {
    text-align: center;
    margin-bottom: 5%;

    button,
    #achievements {
        font-size: 1.5vw;
        background: $gradient-1;
        padding: 1% 4%;
        text-transform: capitalize;
        margin: 0 1%;
        color: #fff;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        border: 2px solid #fff;

        &:hover {
            background: #fff;
            border: 2px solid $color-1;
            transition: .5s all ease;
            color: #000;
        }
    }


}

.gallery-active {
    background: $color-3;
    color: $color_3;
}

.gallery-video {
    @include flex;
    flex-wrap: wrap;

    .video-body-card {
        width: calc(100% / 3.2);
        margin: 1%;
        height: 30vh;
        border: 5px solid $color-1;
    }
}

/*===============================================================
# Testimonials
===============================================================*/
.testimonials {
    padding: 5%;

    .carousel.carousel-slider .control-arrow {
        height: 80%;

        &:hover {
            background: transparent;
        }
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid $color-1;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid $color-1;
    }

    .carousel .control-dots .dot {
        box-shadow: 1px 1px 5px $color-1;
        border: 2px solid $color-1;
        width: 15px;
        height: 15px;
    }

    .carousel {
        .carousel-wrapper {
            width: 100%;

            .carousel-body {
                @include flex;
                margin: 0 5%;

                .testimonial-card {
                    width: calc(100% / 2);
                    margin: 1% 1% 7% 1%;
                    height: 40vh;
                    background: $color-2;
                    padding: 2%;
                    color: #fff;
                    font-family: $text-family;
                    position: relative;

                    p {
                        text-align: left;
                        font-size: 1.05vw;
                    }

                    h6 {
                        font-size: 2vw;
                        margin-bottom: 5%;
                        text-align: center;
                    }

                }

            }


        }


    }
}


/*===============================================================
# Videos
===============================================================*/
#video {
    padding: 5% 0;

    .carousel.carousel-slider .control-arrow {
        height: 80%;

        &:hover {
            background: transparent;
        }
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid $color-1;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid $color-1;
    }

    .carousel .control-dots .dot {
        box-shadow: 1px 1px 5px $color-1;
        border: 2px solid $color-1;
        width: 15px;
        height: 15px;
    }

    .carousel .slide iframe {
        width: 100%;
        margin: 0;
    }

    .carousel {
        padding: 0 5%;

        .carousel-wrapper {
            width: 100%;

            .carousel-body {
                @include flex;

                .testimonial-card {
                    width: calc(100% / 3);
                    margin: 1% 1% 7% 1%;
                    height: 30vh;
                    color: #fff;
                    font-family: $text-family;
                    position: relative;
                    border: 3px solid $color-1;

                    p {
                        text-align: left;
                        font-size: 1.15vw;
                    }

                    h6 {
                        margin-left: auto;
                        font-size: 1.4vw;
                        position: absolute;
                        right: 5%;
                        bottom: 2%;
                        color: #000;
                        padding: 2% 5%;
                        border-radius: 10px;
                    }

                }

            }


        }


    }
}

/*===============================================================
# Pricing Section
===============================================================*/
.pricing {
    padding: 0 5% 5% 5%;

    .pricing-table {
        @include flex;
        width: 100%;

        background: #ffffff;

        .ptable-item {
            width: calc(100% / 4);
            padding: 0;
            margin-right: 2%;
            margin-bottom: 5%;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                .ptable-single {
                    background: $color-3;

                }

                .ptable-title,
                .ptable-price {
                    h2 {
                        color: $color-3;

                    }
                }

                .ptable-header {
                    background: #fff;

                    &::before,
                    &::after {
                        border-bottom: 100px solid $color-3;
                        border-right: 250px solid #fff;
                    }
                }

                .ptable-description {
                    color: #fff;
                    transition: .3s all ease-in-out;

                    li {
                        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
                    }
                }

                .ptable-action {
                    a {
                        transition: .3s all ease-in-out;

                        background: #fff;
                        color: $color-1;
                    }
                }
            }


            .ptable-single {
                position: relative;
                width: 100%;
                overflow: hidden;
                background: #fff;
                box-shadow: 2px 2px 8px rgba($color: #000, $alpha: 0.2);
                height: 100%;

                &:hover {
                    box-shadow: 0 0 10px #999999;
                }
            }

            .ptable-header {
                position: relative;
                width: 100%;
                text-align: center;
                overflow: hidden;
                margin: 0 10%;
                padding: 8% 0 30% 0;
                width: auto;
                background: $color-3;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-bottom: 100px solid #fff;
                    right: 50%;
                    border-right: 250px solid transparent;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-bottom: 100px solid #fff;
                    left: 50%;
                    border-left: 250px solid transparent;
                }
            }

            .ptable-body {
                position: relative;
                width: 100%;
                text-align: center;
                overflow: hidden;
                padding: 20px 0;
                margin-bottom: 20%;
            }

            .ptable-footer {
                position: absolute;
                bottom: 0;
                width: 100%;
                margin-top: 10em;
            }


            .ptable-title {
                position: relative;
                width: 100%;
                text-align: center;

                h2 {
                    color: #fff;
                    font-size: 1.5vw;
                    font-weight: 300;
                    font-family: $title-family;
                }
            }

            .ptable-price {
                position: relative;
                width: 100%;
                text-align: center;

                h2 {
                    margin: 0;
                    color: $color_3;
                    font-size: 3vw;
                    font-weight: 700;
                    margin-left: 15px;
                    color: #fff;

                    small {
                        position: absolute;
                        font-size: 18px;
                        font-weight: 300;
                        margin-top: 16px;
                        margin-left: -15px;
                    }

                    span {
                        margin-left: 3px;
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }

            .ptable-description {
                position: relative;
                width: 100%;
                text-align: center;
                padding: 0 5%;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        font-size: 1.2vw;
                        padding: 2%;
                        border-bottom: 1px solid rgba($color: #000, $alpha: 0.2);
                        font-family: $text-family;
                        margin: 5% 0;
                    }
                }
            }

            .ptable-action {
                position: relative;
                width: 100%;
                text-align: center;

                a {
                    display: inline-block;
                    padding: 3% 7%;
                    color: #fff;
                    font-size: 1.3vw;
                    text-decoration: none;
                    background: $color-3;
                    font-family: $text-family;
                    width: 100%;

                    &:hover {
                        transition: .5s all ease;
                        font-size: 1.5vw;

                    }
                }
            }
        }


    }

}

/*===============================================================
# Contact
===============================================================*/


@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contact {
    padding: $padding;

    .map {
        margin-top: 4.3%;
    }

    .info-item {
        text-align: center;
        height: 100%;

        svg {
            font-size: 48px;
            display: inline-block;
            margin-bottom: 10px;
            color: $color_1;

            path {
                fill: $color-1;
            }
        }

        address {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
            font-family: $text-family;

        }

        h3 {
            font-size: 16px;
            margin-bottom: 15px;
            font-weight: bold;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            color: $color_1;
            font-family: $text-family;

        }

        a {
            font-family: $text-family;

            &:hover {
                color: $color_1;
            }
        }
    }

    .php-email-form {
        box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.1);
        padding: 30px;
        background: var(--color-white);

        .error-message {
            display: none;
            color: $color_2;
            background: var(--color-red);
            text-align: left;
            padding: 15px;
            font-weight: 600;

            br+br {
                margin-top: 25px;
            }
        }

        .sent-message {
            display: none;
            color: $color_2;
            background: $color-1;
            color: #fff;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }

        .loading {
            display: none;
            background: var(--color-white);
            text-align: center;
            padding: 15px;

            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid $color-1;
                border-top-color: #fff;
                animation: animate-loading 1s linear infinite;
            }
        }

        .form-group {
            margin-bottom: 20px;
        }

        input {
            padding: 10px 14px;
            border-radius: 0;
            box-shadow: none;
            font-size: 15px;
            color: #dc1c5c;
            outline: none;
            font-family: $text-family;
        }

        textarea {
            padding: 10px 14px;
            border-radius: 0;
            box-shadow: none;
            font-size: 15px;
            outline: none;
            color: #dc1c5c;
            widows: 100%;
        }

        button[type=submit] {
            background: $gradient-1;
            border: 0;
            padding: 10px 30px;
            color: #fff;
            transition: 0.4s;
            cursor: pointer;
            border: 1px solid transparent;
            font-family: $text-family;

            &:hover {
                background: transparent;
                border: 1px solid #dc1c5c;
                color: #dc1c5c;
                font-weight: bold;
            }
        }
    }


}

.branches {
    padding: 5%;

    .branches-body {
        @include flex;
        flex-wrap: wrap;

        .branches-card {
            width: calc(100% / 3.2);
            padding: 5% 2%;
            background: $gradient-1;
            margin: 1%;
            text-align: center;
            font-family: $text-family;
            color: #fff;
            border-radius: 10px;


            &:hover {
                background: $color-1;
                transition: .5s all ease;
                transform: scale(1.2);
            }

            h6 {
                font-size: 1.7vw;
            }
        }


    }
}

@media (min-width: 768px) {
    .contact {
        .info-item-borders {
            border-left: 1px solid rgba($color: $black, $alpha: 0.2);
            border-right: 1px solid rgba($color: $black, $alpha: 0.2);
        }
    }
}

@media (max-width: 640px) {
    .contact {
        .php-email-form {
            padding: 20px;
        }
    }
}

/*===============================================================
# Terms Of Service
===============================================================*/

.terms-of-service {
    padding: 5%;
    margin-top: 5%;

    .terms-of-service-body {
        .terms-card {
            margin-bottom: 5%;

            h4 {
                font-size: 2vw;
                margin-bottom: 2%;
                text-transform: capitalize;
                color: $color-1;
            }

            ol {
                padding-left: 5%;

                li {
                    list-style-type: unset;
                    font-size: 1.2vw;
                    margin: .5% 0;
                }
            }
        }
    }
}

/*===============================================================
# Privacy Policy
===============================================================*/

.privacy-policy {
    padding: 5%;
    margin-top: 5%;

    .privacy-policy-body {
        .privacy-card {
            p {
                font-size: 1.2vw;
            }

            ol {

                li {
                    list-style-type: unset;
                    font-size: 1.4vw;
                    margin: 3% 0;

                    span {
                        font-weight: bold;
                        color: $black;
                    }
                }
            }

            ul {
                padding-left: 5%;

                li {
                    list-style-type: lower-alpha;
                    font-size: 1.2vw;
                    margin: .5% 0;
                }
            }
        }
    }
}

/*===============================================================
# Footer
===============================================================*/

#footer {
    background: rgba($color: $color-1, $alpha: 0.3);
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;

    .footer-top {
        background: $gradient-1;
        padding: 60px 0 30px 0;

        .footer-info {
            margin-bottom: 30px;

            h3 {
                font-size: 24px;
                margin: 0 0 20px 0;
                padding: 2px 0 2px 0;
                line-height: 1;
                font-weight: 700;
                font-family: $title-family;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                font-family: $text-family;
            }
        }

        .social-links {
            svg {
                font-size: 18px;
                display: inline-block;
                background: #fff;
                line-height: 1;
                padding: 8px 0;
                margin-right: 4px;
                border-radius: 4px;
                text-align: center;
                width: 36px;
                height: 36px;
                transition: 0.3s;

                &:hover {
                    background: $color_1;
                    text-decoration: none;

                    path {
                        fill: #fff;
                    }
                }

                path {
                    fill: $black;
                }
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 600;
            position: relative;
            // padding-bottom: 12px;
            font-family: $title-family;

        }

        p {
            margin-bottom: 0;
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                i {
                    padding-right: 2px;
                    color: $magenta;
                    font-size: 18px;
                    line-height: 1;
                }

                li {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    font-family: $text-family;

                    &:first-child {
                        padding-top: 0;
                    }
                }

                a {
                    color: #fff;
                    transition: 0.3s;
                    display: inline-block;
                    line-height: 1;
                    font-family: $text-family;

                    &:hover {
                        transform: scale(1.2) translateX(10px);
                    }
                }
            }
        }

        .footer-newsletter {
            form {
                margin-top: 10px;
                background: #fff;
                padding: 2px 10px;
                position: relative;
                border: 1px solid #d5d5d5;
                border-radius: 4px;

                input[type=email] {
                    border: 0;
                    padding: 4px;
                    width: calc(100% - 110px);
                    outline: none;
                    color: $color-1;
                }

                input[type=submit] {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    bottom: -1px;
                    border: 0;
                    background: none;
                    font-size: 16px;
                    padding: 0 20px;
                    background: $black;
                    color: #fff;
                    transition: 0.3s;
                    border-radius: 0 4px 4px 0;

                    &:hover {
                        background: $color-1;
                    }
                }
            }

            iframe {
                margin-bottom: 2%;
            }
        }
    }

    .copyright {
        text-align: center;
        padding-top: 30px;
        color: #000;
    }

    .credits {
        padding-top: 10px;
        text-align: center;
        font-size: 13px;
    }
}