@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Charm&family=Kaushan+Script&family=Qwigley&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran&family=Signika+Negative&display=swap');
// fonts
$header-family: 'Qwigley', cursive;
$title-family: 'Catamaran', sans-serif;
$text-family: 'Signika Negative', sans-serif;
// colors
$color-1: #dc1c5c;
$color-2: #e45484;
$color-3: #e46692;
$color-4: #ec8cac;
$magenta: #6c0c2c;
$light-gray: #cccbcb;
$gray: #7c7779;
$dark-gray: #5c5c5c;
$black: #060606;

// sizes

$padding: 5% 5% 0 5%;

// gradients

$gradient-1: radial-gradient(circle, rgba(220, 28, 92, 1) 0%, rgba(236, 140, 172, 1) 100%);
$gradient-2: radial-gradient(circle, rgba(220, 28, 92, .6) 0%, rgba(236, 140, 172, .9) 100%);