@import 'variables.scss';
@import 'mixins.scss';

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    /*===============================================================
    # Common
    ===============================================================*/
    .banner {
        .banner-caption {
            h3 {
                font-size: 5vw;
                transform: translate(-50%, -50%);
                top: 60%;
            }
        }
    }

    .heading {
        h3 {
            font-size: 6vw;

            &:before {
                width: 20%;
            }

            &:after {
                width: 15%;
                top: 115%;
            }
        }
    }

    .whatsapp {
        display: block;
    }

    /*===============================================================
    # Header
    ===============================================================*/
    .header {
        .header-links {
            display: none;
        }

        .header-menu {
            display: block;
        }
    }

    /*===============================================================
    # Slider
    ===============================================================*/
    .carousel {
        margin-top: 20%;

        .carousel-wrapper {
            .carousel-body {
                .carousel-caption {
                    width: 80%;
                    left: 10%;

                    a {
                        font-size: 3vw;
                    }
                }
            }

        }

        .carousel-form {
            width: 100%;
            position: unset;
            background: $gradient-1;

            h4 {
                font-size: 4vw;
            }

            .carousel-form-body {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }

        }
    }

    /*===============================================================
    # About
    ===============================================================*/
    .about {
        flex-wrap: wrap;

        img {
            width: 100%;
            margin-bottom: 5%;
        }

        .about-text {
            width: 100%;
            padding: 0;

            p {
                font-size: 3vw;
            }
        }
    }

    .about-page {
        .about-body {
            padding-top: 10%;

            .founder-message {
                flex-wrap: wrap;

                .img {
                    width: 100%;
                }

                .founder-message-caption {
                    width: 100%;

                    h3 {
                        margin-top: 5%;

                        &:after {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Courses
    ===============================================================*/
    .courses {
        .courses-body {
            .courses-card {
                width: 100%;

                h4 {
                    font-size: 6vw;
                    background: #fff;
                    margin: 3% 5% 5% 5%;
                    padding: 3%;
                    border-radius: 10px;
                }
            }
        }
    }

    .courses-page {
        .courses-body {
            .courses-card {
                flex-direction: column;

                &:nth-child(even) {
                    flex-direction: column;

                    .courses-img {
                        margin-left: 0;
                    }
                }

                .courses-img {
                    width: 100%;
                    margin-bottom: 5%;
                }

                .courses-card-caption {
                    width: 100%;

                    h4 {
                        font-size: 8vw;
                    }

                    span {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .pricing {
        padding: 0;

        .pricing-table {
            flex-wrap: wrap;

            .ptable-item {
                width: 90%;

                .ptable-title {
                    h2 {
                        font-size: 5vw;

                    }
                }

                .ptable-price {
                    h2 {
                        font-size: 7vw;
                    }
                }

                .ptable-description {
                    ul {
                        li {
                            font-size: 4vw;
                        }
                    }
                }

                .ptable-action {
                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Affiliations
    ===============================================================*/
    .affiliations {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;
            }
        }
    }

    #Frames {
        width: 100%;
    }

    /*===============================================================
    # Team
    ===============================================================*/

    .team {
        .team-member {
            h4 {
                font-size: 5vw;
            }

            p {
                font-size: 3vw;
            }
        }
    }

    .team-page {
        .team-body {
            .team-card {
                display: block;

                &:nth-child(even) {
                    img {
                        margin: 0;
                    }
                }

                .team-img {
                    width: 100%;
                    margin: 0;
                }

                .team-text {
                    width: 100%;
                    float: right;
                    padding: 5%;

                    .team-title {
                        h3 {
                            font-size: 4vw;
                            margin-bottom: 1%;
                        }

                        span {
                            font-size: 3vw;
                        }
                    }

                    .team-caption {
                        p {
                            font-size: 2.8vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/
    .testimonials {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 90%;
                        margin: 1% auto;
                        height: 20vh;

                        p {
                            font-size: 2.1vw;
                        }

                        h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Achievements
    ===============================================================*/
    .achievements-content-img {
        img {
            width: 100%;
        }
    }

    /*===============================================================
    # Gallery
    ===============================================================*/
    #gallery .gallery img {
        width: 100%;
        margin: 3% 1%;
    }

    .gallery-filter {

        #achievements,
        button {
            font-size: 3.5vw;
            margin-bottom: 2%;
        }
    }

    .gallery-video {

        flex-direction: column;

        .video-body-card {
            width: 100%;
        }
    }

    /*===============================================================
    # Videos
    ===============================================================*/
    #video {
        .carousel {
            padding: 0;

            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    padding: 0 10%;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 100%;
                        height: 25vh;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Contact
    ===============================================================*/

    .branches {
        .branches-body {
            .branches-card {
                width: calc(100% / 2.1);

                h6 {
                    font-size: 3vw;
                }
            }
        }
    }

    /*===============================================================
    # Terms Of Service
    ===============================================================*/
    .terms-of-service {
        margin-top: 22%;

        .terms-of-service-body {
            margin-top: 10%;

            .terms-card {

                h4 {
                    font-size: 4vw;
                }

                ol {
                    li {
                        font-size: 3vw;

                    }
                }
            }
        }
    }

    /*===============================================================
    # Privacy Policy
    ===============================================================*/
    .privacy-policy {
        margin-top: 20%;

        p {
            font-size: 3vw;
        }

        .privacy-policy-body {
            .privacy-card {
                p {
                    font-size: 3vw;
                }

                ol {
                    padding-left: 4%;

                    li {
                        font-size: 3.4vw;

                        span {
                            font-size: 4.4vw;

                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    /*===============================================================
    # Common
    ===============================================================*/
    .heading {
        h3 {
            font-size: 6vw;

            &:before {
                width: 20%;
            }

            &:after {
                width: 15%;
                top: 115%;
            }
        }
    }

    .banner {
        .banner-caption {
            h3 {
                font-size: 7vw;
                transform: translate(-50%, -50%);
                top: 60%;
            }
        }
    }

    .whatsapp {
        display: block;
    }

    /*===============================================================
    # Header
    ===============================================================*/
    .header {
        .header-links {
            display: none;
        }

        .header-menu {
            display: block;
        }
    }

    /*===============================================================
    # Slider
    ===============================================================*/
    .carousel {
        margin-top: 20%;

        .carousel-wrapper {
            .carousel-body {
                .carousel-caption {
                    width: 80%;
                    left: 10%;

                    a {
                        font-size: 3vw;
                    }
                }
            }

        }

        .carousel-form {
            width: 100%;
            position: unset;
            background: $gradient-1;

            h4 {
                font-size: 4vw;
            }

            .carousel-form-body {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }

        }
    }

    /*===============================================================
    # About
    ===============================================================*/
    .about {
        flex-wrap: wrap;

        img {
            width: 100%;
            margin-bottom: 5%;
        }

        .about-text {
            width: 100%;
            padding: 0;

            p {
                font-size: 3vw;
            }
        }
    }

    .about-page {
        .about-body {
            padding-top: 10%;

            .founder-message {
                flex-wrap: wrap;

                .img {
                    width: 100%;
                }

                .founder-message-caption {
                    width: 100%;

                    h3 {
                        margin-top: 5%;
                        margin-top: 5%;

                        &:after {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Courses
    ===============================================================*/
    .courses {
        .courses-body {
            .courses-card {
                width: 100%;

                h4 {
                    font-size: 6vw;
                    background: #fff;
                    margin: 3% 5% 5% 5%;
                    padding: 3%;
                    border-radius: 10px;
                }
            }
        }
    }

    .courses-page {
        .courses-body {
            .courses-card {
                flex-direction: column;

                &:nth-child(even) {
                    flex-direction: column;

                    .courses-img {
                        margin-left: 0;
                    }
                }

                .courses-img {
                    width: 100%;
                    margin-bottom: 5%;
                }

                .courses-card-caption {
                    width: 100%;

                    h4 {
                        font-size: 8vw;
                    }

                    span {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .pricing {
        padding: 0;

        .pricing-table {
            flex-wrap: wrap;

            .ptable-item {
                width: 90%;

                .ptable-title {
                    h2 {
                        font-size: 5vw;

                    }
                }

                .ptable-price {
                    h2 {
                        font-size: 7vw;
                    }
                }

                .ptable-description {
                    ul {
                        li {
                            font-size: 4vw;
                        }
                    }
                }

                .ptable-action {
                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Affiliations
    ===============================================================*/
    .affiliations {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;
            }
        }
    }

    #Frames {
        width: 100%;
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/
    .testimonials {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 90%;
                        margin: 1% auto;
                        height: 35vh;

                        p {
                            font-size: 2.1vw;
                        }

                        h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Videos
    ===============================================================*/
    #video {
        .carousel {
            padding: 0;

            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    padding: 0 10%;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 100%;
                        height: 25vh;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Team
    ===============================================================*/

    .team {
        .team-member {
            h4 {
                font-size: 5vw;
            }

            p {
                font-size: 3vw;
            }
        }
    }

    .team-page {
        .team-body {
            .team-card {
                display: block;

                &:nth-child(even) {
                    img {
                        margin: 0;
                    }
                }

                .team-img {
                    width: 100%;
                    margin: 0;
                }

                .team-text {
                    width: 100%;
                    float: right;
                    padding: 5%;

                    .team-title {
                        h3 {
                            font-size: 4vw;
                            margin-bottom: 1%;
                        }

                        span {
                            font-size: 3vw;
                        }
                    }

                    .team-caption {
                        p {
                            font-size: 2.8vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/

    /*===============================================================
    # Achievements
    ===============================================================*/
    .achievements-content-img {
        img {
            width: 100%;
        }
    }

    /*===============================================================
    # Gallery
    ===============================================================*/
    #gallery .gallery img {
        width: 100%;
        margin: 3% 1%;
    }

    .gallery-filter {

        #achievements,
        button {
            font-size: 3.5vw;
            margin-bottom: 2%;
        }
    }

    .gallery-video {
        flex-direction: column;

        .video-body-card {
            width: 100%;
        }
    }

    /*===============================================================
    # Contact
    ===============================================================*/

    .branches {
        .branches-body {
            .branches-card {
                width: calc(100% / 2.1);

                h6 {
                    font-size: 3vw;
                }
            }
        }
    }

    /*===============================================================
    # Terms Of Service
    ===============================================================*/
    .terms-of-service {
        margin-top: 22%;

        .terms-of-service-body {
            margin-top: 10%;

            .terms-card {

                h4 {
                    font-size: 4vw;
                }

                ol {
                    li {
                        font-size: 3vw;

                    }
                }
            }
        }
    }

    /*===============================================================
    # Privacy Policy
    ===============================================================*/
    .privacy-policy {
        margin-top: 20%;

        p {
            font-size: 3vw;
        }

        .privacy-policy-body {
            .privacy-card {
                p {
                    font-size: 3vw;
                }

                ol {
                    padding-left: 4%;

                    li {
                        font-size: 3.4vw;

                        span {
                            font-size: 4.4vw;

                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    /*===============================================================
    # Common
    ===============================================================*/
    .heading {
        h3 {
            font-size: 6vw;

            &:before {
                width: 20%;
            }

            &:after {
                width: 15%;
                top: 115%;
            }
        }
    }

    .banner {
        .banner-caption {
            h3 {
                font-size: 7vw;
                transform: translate(-50%, -50%);
                top: 60%;
            }
        }
    }

    .whatsapp {
        display: block;
    }

    /*===============================================================
    # Header
    ===============================================================*/
    .header {
        .header-links {
            display: none;
        }

        .header-menu {
            display: block;
        }
    }

    /*===============================================================
    # Slider
    ===============================================================*/
    .carousel {
        margin-top: 12%;

        .carousel-wrapper {
            .carousel-body {
                .carousel-caption {
                    width: 80%;
                    left: 10%;

                    a {
                        font-size: 3vw;
                    }
                }
            }

        }

        .carousel-form {
            width: 100%;
            position: unset;
            background: $gradient-1;

            h4 {
                font-size: 4vw;
            }

            .carousel-form-body {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }

        }
    }

    /*===============================================================
    # About
    ===============================================================*/
    .about {
        flex-wrap: wrap;

        img {
            width: 100%;
            margin-bottom: 5%;
        }

        .about-text {
            width: 100%;
            padding: 0;

            p {
                font-size: 3vw;
            }
        }
    }

    .about-page {
        .about-body {
            padding-top: 10%;

            .founder-message {
                flex-wrap: wrap;

                .img {
                    width: 100%;
                }

                .founder-message-caption {
                    width: 100%;

                    h3 {
                        margin-top: 5%;
                        margin-top: 5%;

                        &:after {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Courses
    ===============================================================*/
    .courses {
        .courses-body {
            .courses-card {
                width: 100%;

                h4 {
                    font-size: 6vw;
                    background: #fff;
                    margin: 3% 5% 5% 5%;
                    padding: 3%;
                    border-radius: 10px;
                }
            }
        }
    }

    .courses-page {
        .courses-body {
            .courses-card {
                flex-direction: column;

                &:nth-child(even) {
                    flex-direction: column;

                    .courses-img {
                        margin-left: 0;
                    }
                }

                .courses-img {
                    width: 100%;
                    margin-bottom: 5%;
                }

                .courses-card-caption {
                    width: 100%;

                    h4 {
                        font-size: 8vw;
                    }

                    span {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .pricing {
        padding: 0;

        .pricing-table {
            flex-wrap: wrap;

            .ptable-item {
                width: 90%;

                .ptable-title {
                    h2 {
                        font-size: 5vw;

                    }
                }

                .ptable-price {
                    h2 {
                        font-size: 7vw;
                    }
                }

                .ptable-description {
                    ul {
                        li {
                            font-size: 4vw;
                        }
                    }
                }

                .ptable-action {
                    a {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Affiliations
    ===============================================================*/
    .affiliations {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;
            }
        }
    }

    #Frames {
        width: 100%;
    }

    /*===============================================================
    # Team
    ===============================================================*/

    .team {
        .team-member {
            h4 {
                font-size: 5vw;
            }

            p {
                font-size: 3vw;
            }
        }
    }

    .team-page {
        .team-body {
            .team-card {
                display: block;

                &:nth-child(even) {
                    img {
                        margin: 0;
                    }
                }

                .team-img {
                    width: 100%;
                    margin: 0;
                }

                .team-text {
                    width: 100%;
                    float: right;
                    padding: 5%;

                    .team-title {
                        h3 {
                            font-size: 4vw;
                            margin-bottom: 1%;
                        }

                        span {
                            font-size: 3vw;
                        }
                    }

                    .team-caption {
                        p {
                            font-size: 2.8vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/

    /*===============================================================
    # Achievements
    ===============================================================*/
    .achievements-content-img {
        img {
            width: 100%;
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/
    .testimonials {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 90%;
                        margin: 1% auto;
                        height: 30vh;

                        p {
                            font-size: 2.1vw;
                        }

                        h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }


    /*===============================================================
    # Videos
    ===============================================================*/
    #video {
        .carousel {
            padding: 0;

            .carousel-wrapper {
                .carousel-body {
                    flex-direction: column;
                    padding: 0 10%;
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 100%;
                        height: 25vh;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Gallery
    ===============================================================*/
    #gallery .gallery img {
        width: 100%;
        margin: 3% 1%;
    }

    .gallery-filter {

        #achievements,
        button {
            font-size: 3.5vw;
            margin-bottom: 2%;
        }
    }

    .gallery-video {
        flex-direction: column;

        .video-body-card {
            width: 100%;
        }
    }

    /*===============================================================
    # Contact
    ===============================================================*/

    .branches {
        .branches-body {
            .branches-card {
                width: calc(100% / 2.1);

                h6 {
                    font-size: 3vw;
                }
            }
        }
    }

    /*===============================================================
    # Terms Of Service
    ===============================================================*/
    .terms-of-service {
        margin-top: 22%;

        .terms-of-service-body {
            margin-top: 10%;

            .terms-card {

                h4 {
                    font-size: 4vw;
                }

                ol {
                    li {
                        font-size: 3vw;

                    }
                }
            }
        }
    }

    /*===============================================================
    # Privacy Policy
    ===============================================================*/
    .privacy-policy {
        margin-top: 20%;

        p {
            font-size: 3vw;
        }

        .privacy-policy-body {
            .privacy-card {
                p {
                    font-size: 3vw;
                }

                ol {
                    padding-left: 4%;

                    li {
                        font-size: 3.4vw;

                        span {
                            font-size: 4.4vw;

                        }
                    }
                }
            }
        }

    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {


    /*===============================================================
    # Common
    ===============================================================*/

    .container {
        max-width: 100%;
    }

    .heading {
        h3 {
            font-size: 6vw;

            &:before {
                width: 20%;
            }

            &:after {
                width: 15%;
            }
        }
    }

    .banner {
        .banner-caption {
            h3 {
                font-size: 5vw;
                transform: translate(-50%, -50%);
                top: 60%;
            }
        }
    }

    .whatsapp {
        display: block;
    }

    /*===============================================================
    # Header
    ===============================================================*/
    .header {
        .header-links {
            display: none;
        }

        .header-menu {
            display: block;
        }
    }

    /*===============================================================
    # About
    ===============================================================*/
    .about-page {
        .about-body {
            padding-top: 10%;

            .founder-message {
                flex-wrap: wrap;

                .img {
                    width: 100%;
                }

                .founder-message-caption {
                    width: 100%;

                    h3 {
                        margin-top: 1%;

                        &:after {
                            width: 70%;
                        }

                    }

                    p {
                        font-size: 2vw;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Team
    ===============================================================*/

    .team {
        .team-member {
            h4 {
                font-size: 1.5vw;
            }

            p {
                font-size: 1vw;
            }
        }
    }

    .team-page {
        .team-body {
            .team-card {
                display: block;

                &:nth-child(even) {
                    img {
                        margin: 0;
                    }
                }

                .team-img {
                    width: 100%;
                    margin: 0;
                }

                .team-text {
                    width: 100%;
                    float: right;
                    padding: 5%;

                    .team-title {
                        h3 {
                            font-size: 4vw;
                            margin-bottom: 1%;
                        }

                        span {
                            font-size: 3vw;
                        }
                    }

                    .team-caption {
                        p {
                            font-size: 2.8vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/
    .testimonials {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 90%;

                        p {
                            font-size: 1.4vw;
                        }

                        h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Gallery
    ===============================================================*/
    .gallery-video {
        .video-body-card {
            width: calc(100% /2.2);
        }
    }

    /*===============================================================
    # Terms Of Service
    ===============================================================*/
    .terms-of-service {
        margin-top: 22%;

        .terms-of-service-body {
            margin-top: 10%;

            .terms-card {

                h4 {
                    font-size: 3vw;
                }

                ol {
                    li {
                        font-size: 2vw;

                    }
                }
            }
        }
    }

    /*===============================================================
    # Privacy Policy
    ===============================================================*/
    .privacy-policy {
        margin-top: 20%;

        p {
            font-size: 2.4vw;
        }

        .privacy-policy-body {
            .privacy-card {
                p {
                    font-size: 2.4vw;
                }

                ol {
                    padding-left: 4%;

                    li {
                        font-size: 2.4vw;

                        span {
                            font-size: 3.4vw;

                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    /*===============================================================
    # Common
    ===============================================================*/

    .container {
        max-width: 100%;
    }

    .heading {
        h3 {
            font-size: 6vw;

            &:before {
                width: 20%;
            }

            &:after {
                width: 15%;
            }
        }
    }

    .banner {
        .banner-caption {
            h3 {
                font-size: 5vw;
                transform: translate(-50%, -50%);
                top: 60%;
            }
        }
    }

    .whatsapp {
        display: block;
    }

    /*===============================================================
    # Header
    ===============================================================*/
    .header {
        .header-links {
            display: none;
        }

        .header-menu {
            display: block;
        }
    }

    /*===============================================================
    # About
    ===============================================================*/
    .about-page {
        .about-body {
            padding-top: 10%;

            .founder-message {
                flex-wrap: wrap;

                .img {
                    width: 100%;
                }

                .founder-message-caption {
                    width: 100%;

                    h3 {
                        margin-top: 1%;

                        &:after {
                            width: 70%;
                        }

                    }

                    p {
                        font-size: 2vw;
                    }
                }
            }
        }
    }

    /*===============================================================
    # Team
    ===============================================================*/

    .team {
        .team-member {
            h4 {
                font-size: 1.5vw;
            }

            p {
                font-size: 1vw;
            }
        }
    }

    .team-page {
        .team-body {
            .team-card {
                display: block;

                &:nth-child(even) {
                    img {
                        margin: 0;
                    }
                }

                .team-img {
                    width: 100%;
                    margin: 0;
                }

                .team-text {
                    width: 100%;
                    float: right;
                    padding: 5%;

                    .team-title {
                        h3 {
                            font-size: 4vw;
                            margin-bottom: 1%;
                        }

                        span {
                            font-size: 3vw;
                        }
                    }

                    .team-caption {
                        p {
                            font-size: 2.8vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Testimonials
    ===============================================================*/
    .testimonials {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    margin-bottom: 15%;

                    .testimonial-card {
                        width: 90%;

                        p {
                            font-size: 1.4vw;
                        }

                        h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    /*===============================================================
    # Terms Of Service
    ===============================================================*/
    .terms-of-service {
        margin-top: 22%;

        .terms-of-service-body {
            margin-top: 10%;

            .terms-card {

                h4 {
                    font-size: 3vw;
                }

                ol {
                    li {
                        font-size: 2vw;

                    }
                }
            }
        }
    }

    /*===============================================================
    # Privacy Policy
    ===============================================================*/
    .privacy-policy {
        margin-top: 20%;

        p {
            font-size: 2.4vw;
        }

        .privacy-policy-body {
            .privacy-card {
                p {
                    font-size: 2.4vw;
                }

                ol {
                    padding-left: 4%;

                    li {
                        font-size: 2.4vw;

                        span {
                            font-size: 3.4vw;

                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}